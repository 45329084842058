.safeAreaPaddingBottom {
  padding-bottom: env(safe-area-inset-bottom);
}
.safeAreaPaddingTop {
  padding-top: env(safe-area-inset-top);
}
.safeAreaPaddingLeft {
  padding-left: env(safe-area-inset-left);
}
.safeAreaPaddingRight {
  padding-right: env(safe-area-inset-right);
}
.safeAreaPadding {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
.safeAreaPaddingExceptBottom {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

/* 이상하게.. top을 살짝이라도 줘야 먹히는 구간이 있다. tricky하게 수정 */
.safeAreaPaddingExceptBottomBackground {
  padding-top: calc(env(safe-area-inset-top) * 0.001);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.safeAreaPaddingExceptTop {
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.safeAreaMarginBottom {
  margin-bottom: env(safe-area-inset-bottom);
}
.safeAreaMarginTop {
  margin-top: env(safe-area-inset-top);
}
.safeAreaMarginLeft {
  margin-left: env(safe-area-inset-left);
}
.safeAreaMarginRight {
  margin-right: env(safe-area-inset-right);
}
.safeAreaMargin {
  margin-bottom: env(safe-area-inset-bottom);
  margin-top: env(safe-area-inset-top);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}
.safeAreaMarginExceptBottom {
  margin-top: env(safe-area-inset-top);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}

.safeAreaMarginExceptTop {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}